<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Laporan pengeluaran Apotik <b>{{clinic_name}}</b></div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table/>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import Table from '@/component/reports/PharmacyExpenseReport.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  components: {
    Card,
    Table
  },

  data(){
    return{
      clinic_name : window.configuration == null ? "Medhop" :  window.configuration.clinic_name
    }
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan" },
      { title: "Laporan Pengeluaran Apotik" },
    ])
  },

}
</script>

<style>
</style>