<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-start mt-n3">
      <div class="col-md-9 col-12" >
          <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;"  @click="btnActiveOnClick('days')"  v-bind:class="{'btn-info' : btnActive.days, 'btn-outline-info' : btnActive.days == false   }">Hari Ini</button>
          <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;"  @click="btnActiveOnClick('month')"  v-bind:class="{'btn-info' : btnActive.month, 'btn-outline-info' : btnActive.month == false  }">Bulan Ini</button>
          <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;"  @click="btnActiveOnClick('lastMonth')"  v-bind:class="{'btn-info' : btnActive.lastMonth, 'btn-outline-info' : btnActive.lastMonth == false  }">Bulan Kemarin</button>
          <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;"  @click="btnActiveOnClick('year')"  v-bind:class="{'btn-info' : btnActive.year, 'btn-outline-info' : btnActive.year == false  }">Tahun Ini</button>
          <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;"   v-bind:class="{'btn-info' : btnActive.period, 'btn-outline-info' : btnActive.period == false  }" @click="btnPeriodeOnClick">Periode</button>
      </div>
      
       <div class="col-md-3">
        <b-form-select
          id="input-period"
          v-model="perPage"
          :options="formats"
          @change="perPageNumber"
          placeholder="Per Halaman (Def. 20)"
        ></b-form-select>
      </div>
    </div>

    <div class="row justify-content-between align-items-center mt-2">
      <div class="col-md-6" v-if="onShowPeriod">
        <span class="d-none d-sm-block">
          <b-input-group>
            
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-button squared variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <template #append>
              <b-button squared @click="filterByPeriode" variant="success"
                >Cari</b-button
              >
              <b-button squared @click="resetFilterPeriode" variant="danger"
                >Reset</b-button
              >
            </template>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
                <template #append>
                  <b-button squared @click="filterByPeriode" variant="success"
                    >Cari</b-button
                  >
                  <b-button squared @click="resetFilterPeriode" variant="danger"
                    >Reset</b-button
                  >
                </template>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>
      <div class="col-md-6" v-if="onShowPeriod == false"></div>
      <div class="col-md-4 d-flex justify-content-end">
        <button
          class="btn btn-success mr-1"
          v-b-tooltip.hover
          title="Grafik"
          @click="$router.push('/report/pharmacy-expense-chart')"
        >
          Grafik <i class="fas fa-chart-bar px-0 ml-1"></i>
        </button>
        <button
          class="btn btn-primary mr-1"
          @click="btnExportOnClick"
        >
          Ekspor <i class="fas fa-print px-0 ml-1"></i>
        </button>
      </div>
      <!-- <button
        class="btn btn-primary mr-1"
        v-b-tooltip.hover
        title="Print"
        @click="btnPrintOnClick"
      >
        <i class="fas fa-print px-0"></i>
      </button>
      <button
        class="btn btn-info mr-1"
        v-b-tooltip.hover
        title="Download Pdf"
        @click="btnDownloadOnClick"
      >
        <i class="fas fa-file-download px-0"></i>
      </button>
      <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
      ><i class="fas fa-file-excel px-0"></i></button> -->
     
    </div>

    <div class="row justify-content-between align-items-center mt-6">
      <div class="col-md-4">
        <span
          style="width: 150px; color: #245590 !important"
          class="font-weight-bold"
          >{{recordData}} Baris Data</span>
      </div>

      <div class="col-md-6 d-flex justify-content-end">
        <span
          style="width: 150px; color: #245590 !important"
          class="font-weight-bold"
          >Total :</span
        >
        <span
          v-if="summary.length != 0"
          class="font-weight-bold"
          style="color: #245590 !important"
          >{{ "Rp. " + parseInt(summary).toLocaleString('id-ID') }}</span
        >
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      class="mt-3"
      :items="items"
      :fields="fields"
      responsive
      :style="'white-space: nowrap'"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '20%' : '' }"
        />
      </template>
      <template #cell(display_amount)="data">
        {{ parseInt(data.item.display_amount).toLocaleString("id-ID") }}
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Pengeluaran Apotik'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >

      <section slot="pdf-content">
        <Print
          :items="items"
          :summary="summary"
        />
      </section>
    </vue-html2pdf>

    <ModalReport 
      :reportType="'pharmacy-expense-report'"
      :reportName="'Pengeluaran Apotek'"
      :dataModal="dataModal"
      :excel="excelData"
    />
  </div>
</template>

<script>
import XLSX from "xlsx";
import VueHtml2pdf from "vue-html2pdf"
import module from "@/core/modules/CrudModule.js";
import Print from '@/component/reports-print/PharmacyExpenseReport.vue'
import ModalReport from "@/component/general/ModalReport.vue"
import moment from 'moment';

export default {
  props: {
    // items: Array,
    // summary: Array,
    // perPage: Number,
    // currentPage: Number,
    // totalRows: Number,
  },

  components: {
    XLSX,
    VueHtml2pdf,
    Print,
    ModalReport,
  },

  data() {
    return {
      // data
      summary: [],
      // Table Items
      items: [],
      excelData:[],
      dataModal:[],
      // Filter
      filter: {
        start_date: '',
        end_date: ''
      },
       // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days : true,
        month : false,
        lastMonth : false,
        year : false,
        period : false,
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      recordData:0,
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date_display",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "name",
          label: "Keterangan",
          sortable: true,
        },
        {
          key: "pharmacy_expense_category_name",
          label: "Kategori",
          sortable: true,
        },
        {
          key: "display_amount",
          label: "Nominal (Rp)",
          sortable: true,
        },
        {
          key: "created_by_name",
          label: "Pembuat",
          sortable: true,
        },
      ],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    };
  },

  methods: {

    resetFilterPeriode() {
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.pagination()
    },

    async pagination() {
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(`pharmacy-expense-reports`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      // let responseReport = await module.get(`pharmacy-expense-no-pagination-reports`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      // let responseExcel = await module.get(`pharmacy-expense-excel-reports`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      

      let pagination = response.meta.pagination
      this.items = response.data
      // this.excelData = responseExcel
      // this.dataModal = responseReport
      this.totalRows = pagination.total
      this.recordData = pagination.total;

      let a
      this.summary = 0
      for (a = 0; a < this.items.length; a++) {
          this.summary += parseInt(this.items[a].display_amount)
      }
    },

    async btnExportOnClick(){
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let responseReport = await module.get(`pharmacy-expense-no-pagination-reports`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let responseExcel = await module.get(`pharmacy-expense-excel-reports`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      
      this.excelData = responseExcel
      this.dataModal = responseReport
      this.$bvModal.show('modal-report')
    },

     perPageNumber() {
      this.pagination()
    },
    
    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    filterByPeriode(sended) {
      this.pagination()
    },

   btnPeriodeOnClick(){
      if(this.onShowPeriod == false){
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      }else{
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type){
      if(type == 'days'){
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'month'){
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'lastMonth'){
        // set time
        this.filter.start_date = moment().subtract(1, 'months').startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1, 'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'year'){
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'period'){
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    },
  },
  mounted() {
    // Get Data
    this.pagination()
  },
};
</script>

<style>
</style>